import {Box} from '@primer/react'

export function wrapElement(
  wrappedElement: React.ReactNode,
  leadingElement?: React.ReactNode,
  key?: string,
  leadingElementWidth?: number,
) {
  // 16 accounts for the gap between the leading element and the wrapped element
  const margin = (leadingElementWidth ?? 40) + 16

  const baseStyling = {display: 'flex', gap: 3}
  const styling = leadingElement ? baseStyling : {...baseStyling, marginLeft: [0, 0, margin, margin]}
  return (
    <Box sx={styling} key={key}>
      {leadingElement}
      {wrappedElement}
    </Box>
  )
}
